<template>
  <div class="container">
    <van-nav-bar
        left-text="公司荣耀"
        left-arrow fixed placeholder
        @click-left="$router.back()"
        v-if="isShow"
    />
    <div class="top">
      <img src="../../../static/img/glory/top_02.jpg" alt="">
    </div>
    <div class="main">
      <p class="info">
        易安捷是行业首家国家电子商务示范企业、国家数字商务示范企业、国家物流标准化试点企业、省重点服务业企业和省高端品牌培育企业，公司先后被认定山东服务名牌和山东知名品牌，是安全防护用品行业国内最大流通企业。</p>
      <div class="glory">
        <div class="item">
          <img src="../../../static/img/glory/1.png" alt="">
          <p>国家电子商务示范企业</p>
        </div>
        <div class="item">
          <img src="../../../static/img/glory/2.png" alt="">
          <p>国家电子商务示范企业</p>
        </div>
        <div class="item">
          <img src="../../../static/img/glory/3.png" alt="">
          <p>山东省重点服务企业</p>
        </div>
        <div class="item">
          <img src="../../../static/img/glory/4.png" alt="">
          <p>山东省重点服务企业</p>
        </div>
        <div class="item">
          <img src="../../../static/img/glory/5.png" alt="">
          <p>山东知名品牌</p>
        </div>
        <div class="item">
          <img src="../../../static/img/glory/6.png" alt="">
          <p>沂蒙新动能领军企业</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Glory",
  data(){
    return {
      isShow : true
    }
  },
  created() {
    let i = window.history.length;
    if(i == 1){
      this.isShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  .top {
    img {
      display: block;
      width: 100%;
    }
  }

  .main {
    box-sizing: border-box;
    padding: 0 15px 30px;
    margin-top: 15px;

    .info {
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 27px;
      letter-spacing: 0px;
      color: #666666;
      text-indent: 2em;
      margin-bottom: 10px;
    }

    .glory {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        text-align: center;
        margin-bottom: 15px;

        img {
          width: 100%;
        }

        p {
          font-family: PingFang-SC-Medium;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          margin-top: 5px;
          letter-spacing: 0px;
          color: #666666;
        }
      }
    }
  }
}

::v-deep .van-nav-bar__arrow {
  font-size: 19px;
  color: #666666;
}

::v-deep .van-nav-bar__text {
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>
